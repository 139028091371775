@mixin Section {
  .section {
    display: grid;
    grid-template-rows: auto 1fr;
    height: auto;
  }

  .section__header-wrapper {
    @extend %vf-has-box-shadow;
    background-color: $color-x-light;
  }

  .section__content {
    // Raise the content above the sidebar so that the card shadows are on top.
    z-index: 1;
  }
}
