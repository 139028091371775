@mixin maas-forms {
  // Adds an "X" close button to the right of an input. Used in Add Machine form
  // for removing extra MAC addresses.
  .p-input--closeable {
    position: relative;

    .p-close-input {
      margin: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  // Align icon size with form validation font size
  .p-form-validation__message [class*="p-icon--"] {
    @extend %icon;
    @include vf-icon-size(#{map-get($font-sizes, small)}rem);
  }

  // Add class to checkbox so that label does not change opacity when disabled
  input[disabled],
  input[disabled="disabled"] {
    &[type="checkbox"].keep-label-opacity + label {
      opacity: 1;

      &::before,
      &::after {
        opacity: 0.5;
      }
    }
  }

  // Custom styling for form fields inside a table.
  .p-form--table {
    input,
    select {
      margin-bottom: 0;
      min-width: 0;
    }

    .p-form-validation__message {
      margin-top: 0;
    }
  }

  // Override Vanilla's default light text for readonly textareas and make text
  // small. Used for displaying very long strings, e.g. certificates.
  .p-textarea--readonly {
    color: $color-dark !important;
    font-size: #{map-get($font-sizes, small)}rem;
  }
}
