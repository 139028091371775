@mixin SourceMachineSelect {
  .source-machine-select {
    @extend %vf-is-bordered;
    @extend %vf-has-round-corners;
    padding: $spv--medium $sph--large 0 $sph--large;
  }

  .source-machine-select__table {
    max-height: 22rem;
    overflow: auto;

    // The default <strong> font-weight is a bit subtle for highlighting
    // substrings, so it's increased slightly here.
    strong {
      font-weight: 600;
    }
  }

  .source-machine-select__row {
    cursor: pointer;

    &:hover {
      background-color: $color-light;
    }
  }
}
