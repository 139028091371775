// Vanilla settings:
$grid-max-width: math.div(1440, 16) * 1rem; // express in rems for easier calculations

$color-navigation-background: #666;
$color-navigation-background--hover: darken($color-navigation-background, 3%);

$breakpoint-kvm-resources-card: 1300px;
$breakpoint-x-large: 1440px;
$breakpoint-navigation-threshold: 925px;
$increase-font-size-on-larger-screens: false;
