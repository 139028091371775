/**
 * Vanilla overrides.
 * {Date} {Person}: {Description of issue}
 * {Link to relevant GitHub issue}
 */

// 10-07-2020 Caleb: Slider input misaligned when using type="number" + wrapper
// should mirror normal form field spacing.
// https://github.com/canonical-web-and-design/vanilla-framework/issues/3163
.p-slider__wrapper {
  margin-bottom: $input-margin-bottom;
}
.p-slider__input {
  -moz-appearance: textfield;
  height: auto;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// 29-04-2022 Huw: Fix for navigation buttons not being full width inside the
// mobile menu.
// https://github.com/canonical-web-and-design/vanilla-framework/issues/4444
button.p-navigation__link {
  @media screen and (max-width: $breakpoint-navigation-threshold) {
    text-align: left;
    width: 100%;
  }
}

// 09-05-2022 Caleb: Side nav sets background to white on full-screen
// https://github.com/canonical-web-and-design/vanilla-framework/issues/4458
.p-side-navigation .p-side-navigation__drawer {
  @media screen and (min-width: $breakpoint-large) {
    background-color: transparent;
  }
}
